<template>
  <div class="about">
    <h1>This is an about page</h1>
    <free-text specialCharacters v-model="test"></free-text>
    <p>{{ test }}</p>
    <multiple-choice
      :options="['Opt1', 'opt2', 'opt3', 'opt4', 'opt5']"
      @input="test2 = 'option ' + $event + ' selected'"
      :correctOption="ans"
    ></multiple-choice>
    <p @click="correct()">{{ test2 }}</p>
  </div>
</template>

<script>
import FreeText from "@/components/input/FreeText.vue";
import MultipleChoice from "../components/input/MultipleChoice.vue";
export default {
  components: {
    FreeText,
    MultipleChoice
  },
  data: function() {
    return {
      test: "this text should change",
      test2: "no option selected",
      ans: null
    };
  },
  methods: {
    correct() {
      this.ans = 1;
    }
  }
};
</script>
